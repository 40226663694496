<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="border-radius">
      <v-card-actions
        ><v-spacer></v-spacer
        ><v-btn icon @click="dialog = false">
          <v-icon small>close</v-icon></v-btn
        ></v-card-actions
      >
      <v-card-text class="pt-0">
        <div class="mb-5 title font-weight-medium text-center">
          {{ $t("group_list_page.delete_dialog_title", { name: groupName }) }}
        </div>
        <v-text-field
          v-model="confirmation"
          :placeholder="$t('group_list_page.confirmation_placeholder')"
        ></v-text-field>
        <div class="text-center">
          <v-btn round class="text-none" @click="cancel">{{
            $t("common.cancel")
          }}</v-btn>
          <v-btn
            round
            class="red white--text text-none"
            :disabled="!canConfirm"
            @click="confirm"
            >{{ $t("common.confirm") }}</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    groupName: "",
    confirmation: "",
    resolve: null,
  }),
  computed: {
    canConfirm() {
      return (
        this.confirmation ===
        this.$t("group_list_page.delete_confirmation_text")
      );
    },
  },
  methods: {
    async open(groupName) {
      this.groupName = groupName;
      this.confirmation = "";

      this.dialog = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    cancel() {
      this.dialog = false;
      this.resolve(false);
    },
    async confirm() {
      this.dialog = false;
      this.resolve(true);
    },
  },
};
</script>
