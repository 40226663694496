<template>
  <v-container class="general">
    <transition name="fade" mode="out-in">
      <div v-if="!isLoading">
        <PageTitle
          v-if="groups && groups.length > 0"
          :title="$t('change-group')"
          tooltip="GroupList"
        />
        <!-- New app -->
        <v-layout
          v-if="(!groups || !groups.length > 0) && canCreateGroup()"
          row
          wrap
          class="mt-50 text-center"
        >
          <v-flex class="xs12 mb-2">
            <v-btn
              fab
              class="sw-accent-bg sw-on-accent fab-xl"
              :to="{ name: 'groups_create_wizard' }"
            >
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-flex>
          <v-flex class="xs12 sw-caption">{{
            $t("group_list_page.create_group")
          }}</v-flex>
        </v-layout>
        <!-- List of apps -->
        <v-layout v-if="groups && groups.length" row wrap>
          <v-flex
            v-for="(group, groupIndex) in groups"
            :key="`group-${groupIndex}`"
            class="xs3 pa-1"
            :class="[group.published ? '' : 'opacity']"
          >
            <v-card
              hover
              class="pa-0 border-radius"
              @click="showGroup(group)"
              @mouseenter="$event.target.classList.add('hover')"
              @mouseleave="$event.target.classList.remove('hover')"
            >
              <v-layout
                :class="['row wrap', { 'pt-4': !isGroupActionsVisible }]"
              >
                <v-flex
                  v-if="isGroupActionsVisible"
                  class="xs12 pa-2 text-xs-right group-actions"
                >
                  <v-tooltip top>
                    <v-btn
                      slot="activator"
                      icon
                      flat
                      class="mr-0 ml-1 my-0"
                      @click.stop="duplicateGroup(group.id)"
                      :disabled="!canCreateGroup"
                    >
                      <font-awesome-icon
                        icon="clone"
                        :style="{ fontSize: '12px' }"
                        :class="canEditGroup ? 'sw-accent' : 'grey--text'"
                      ></font-awesome-icon>
                    </v-btn>
                    <span>{{ $t("duplicateGroupTooltip") }}</span>
                  </v-tooltip>
                  <template v-if="canDeleteGroup()">
                    <v-tooltip top>
                      <v-btn
                        slot="activator"
                        icon
                        flat
                        class="ma-0"
                        @click.stop="deleteGroup(group.id)"
                      >
                        <font-awesome-icon
                          icon="trash"
                          class="sw-accent fai-small"
                        ></font-awesome-icon>
                      </v-btn>
                      <span>{{ $t("group_list_page.delete_tooltip") }}</span>
                    </v-tooltip>
                  </template>
                  <v-tooltip top>
                    <v-btn
                      slot="activator"
                      icon
                      flat
                      class="mr-0 ml-1 my-0"
                      @click.stop="toGroupEdit(group)"
                      :disabled="!canEditGroup"
                    >
                      <font-awesome-icon
                        icon="pen"
                        :style="{ fontSize: '12px' }"
                        :class="canEditGroup ? 'sw-accent' : 'grey--text'"
                      ></font-awesome-icon>
                    </v-btn>
                    <span>{{ $t("edit-group") }}</span>
                  </v-tooltip>
                </v-flex>
                <v-flex
                  class="xs12 px-4 pb-4 text-xs-center"
                  :class="{ 'pt-4': !canEditGroup }"
                >
                  <template
                    v-if="
                      group.attributes.group_image &&
                      group.attributes.group_image.presets &&
                      group.attributes.group_image.presets.thumb
                    "
                  >
                    <v-avatar :size="80" class="mb-3">
                      <v-img
                        :src="group.attributes.group_image.presets.thumb"
                        aspect-ratio="1"
                      ></v-img>
                    </v-avatar>
                  </template>
                  <template v-else>
                    <v-avatar
                      :size="80"
                      :class="'mb-3 display-1' + styleClass(group.id)"
                      style="text-transform: uppercase; color: #fff"
                    >
                      <strong>{{ group.name.substring(0, 1) }}</strong>
                    </v-avatar>
                  </template>
                  <div class="pt-1" style="text-transform: uppercase">
                    <strong>{{ group.name }}</strong>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex v-if="totalPages > 1" class="xs12 mt-4 text-xs-center">
            <v-pagination
              v-model="page"
              :length="totalPages"
              :total-visible="totalVisible"
              @input="listGroups"
            ></v-pagination>
          </v-flex>
        </v-layout>
        <AppSpeedDial
          v-if="groups && groups.length && canCreateGroup()"
          @click="$router.push({ name: 'groups_create_wizard' })"
          :hint="$t('group_list_page.speed_dial_hint')"
        />
      </div>
    </transition>
    <GroupDuplicateDialog ref="groupDuplicate" />
    <GroupDeleteDialog ref="groupDeleteDialog" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { permissionMixin } from "@/mixins/permissionMixin.js";
import _ from "lodash";
import GroupDuplicateDialog from "@/components/GroupListPage/GroupDuplicateDialog.vue";
import GroupDeleteDialog from "@/components/GroupListPage/GroupDeleteDialog.vue";
import { groupMenuMixin } from "../mixins/groupMenuMixin";

export default {
  mixins: [permissionMixin, groupMenuMixin],
  data: () => ({
    isLoading: false,
    groups: [],
    model: {},
    page: 1,
    perPage: 16,
    totalPages: null,
    totalVisible: 5,
    fab: false,
  }),
  computed: {
    ...mapGetters([
      "currentUser",
      "userRolesInGroup",
      "getLeftMenu",
      "appLanguage",
    ]),
    isSuperAdmin() {
      if (!this.currentUser || !this.currentUser.roles) {
        return false;
      }
      return this.currentUser.roles.includes("admin");
    },
    isGroupActionsVisible() {
      return this.isSuperAdmin ||
        process.env.VUE_APP_IS_GROUP_ACTIONS_VISIBLE === "true"
        ? true
        : false;
    },
  },
  components: {
    GroupDuplicateDialog,
    GroupDeleteDialog,
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page);
    }

    this.listGroups();
  },
  methods: {
    async listGroups() {
      if (!this.currentUser || !this.currentUser.id) return;

      this.$router.replace(
        {
          query: {
            page: this.page,
          },
        },
        (err) => {},
      );

      this.isLoading = true;

      const params = {
        sortby: "updated_at",
        sortdir: "desc",
        managed: 1,
        page: this.page,
        per_page: this.perPage,
        with_attributes: 1,
        expand_attributes: 1,
      };

      this.$api.groups.list(params).then(
        (res) => {
          if (!res) {
            this.isLoading = false;
            return;
          }

          if (res.data.data.length === 1 && !this.canCreateGroup()) {
            this.$router
              .push({
                name: "get_started",
                params: {
                  group_id: res.data.data[0].id,
                },
              })
              .catch(() => {});
          } else {
            this.isLoading = false;
            this.groups = res.data.data;
            this.totalPages = res.data.pagination.total_pages;
          }
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    async showGroup(group) {
      if (!group || !group.id) return;

      if (
        !group.attributes ||
        !group.attributes.is_complete ||
        !group.published
      ) {
        this.$router
          .push({
            name: "groups_edit_wizard",
            params: { group_id: group.id },
          })
          .catch(() => {});

        return;
      }

      const res1 = await this.$api.groups.get(group.id, { with_attributes: 1 });

      if (!res1.data) return;

      this.$store.dispatch("setActiveGroup", res1.data.data);
      this.$store.dispatch(
        "setUserRolesInGroup",
        res1.data.meta.user_roles_in_group,
      );

      const params = [
        group.id,
        {
          lang: this.appLanguage,
        },
      ];

      const res2 = await this.$store.dispatch("getGroupLeftMenu", params);

      if (!res2) return;

      const allowedGroupMenu = this.groupMenu();

      if (allowedGroupMenu.length) {
        this.$router
          .push({
            name: allowedGroupMenu[0].route.name,
            params: { group_id: group.id },
          })
          .catch(() => {});
      } else {
        this.$router
          .push({ name: "404", params: { group_id: group.id } })
          .catch(() => {});
      }
    },
    toGroupEdit(group) {
      if (!group || !group.id) return;

      this.$router.push(
        {
          name: "groups_edit_wizard",
          params: { group_id: group.id },
        },
        () => {},
      );
    },
    styleClass(id) {
      if (!id) return;
      return " color-" + (id % 10);
    },
    duplicateGroup(id) {
      if (!id) return;
      this.$refs.groupDuplicate.openDialog(id);
    },
    async deleteGroup(id) {
      const group = this.groups.find((el) => el.id === id);

      const isConfirmed = await this.$refs.groupDeleteDialog.open(group.name);

      if (!isConfirmed) return;

      this.isLoading = true;

      this.$api.groups.delete(id).then(
        () => {
          this.isLoading = false;

          this.listGroups();
        },
        () => {
          this.$store.dispatch("addErrorNotification", {
            message: this.$t("group_list_page.delete_error"),
          });
          this.isLoading = false;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.fab-xl {
  min-width: 100px;
  min-height: 100px;

  .v-icon {
    font-size: 40px;
    font-weight: 700;
  }
}

.mt-50 {
  margin-top: 200px;
}

.group-actions {
  opacity: 0;
  transition: 0.4s;
}

.hover {
  .group-actions {
    opacity: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.15s;
}

.fade-enter-active {
  transition-delay: 0.15s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.opacity {
  opacity: 0.5;
}

.theme--light.v-card {
  height: 100%;
}

.color {
  &-0 {
    background: #ffc107;
  }

  &-1 {
    background: #f44336;
  }

  &-2 {
    background: #e91e63;
  }

  &-3 {
    background: #9c27b0;
  }

  &-4 {
    background: #673ab7;
  }

  &-5 {
    background: #3f51b5;
  }

  &-6 {
    background: #00bcd4;
  }

  &-7 {
    background: #009688;
  }

  &-8 {
    background: #4caf50;
  }

  &-9 {
    background: #ff9800;
  }
}
</style>
