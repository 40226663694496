<template>
  <v-dialog v-model="dialog" persistent width="800">
    <v-card class="border-radius">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text class="pt-0">
        <div class="pb-4 headline text-xs-center">
          {{ $t("gw-set-up-your-app") }}
        </div>
        <v-form data-vv-scope="form-3">
          <div v-if="errorText" class="mb-4 theme-danger">{{ errorText }}</div>
          <div class="mb-2">
            <v-text-field
              v-model="group.name"
              v-validate.disable="'required'"
              :error-messages="errors.collect('form-3.name')"
              :data-vv-name="'name'"
              :data-vv-as="$t('gw-event-name')"
              :label="$t('gw-event-name-for-everyone-to-see')"
            ></v-text-field>
          </div>
        </v-form>
        <div class="text-xs-center">
          <v-btn round class="text-none" @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            round
            class="theme-primary-bg theme-on-primary text-none"
            @click="duplicateGroup"
            :loading="isVuexLoading('duplicateGroup')"
          >
            {{ $t("gw-duplicate-btn-text") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    errorText: "",
    group: {},
  }),
  methods: {
    openDialog(groupId) {
      this.errors.clear();
      this.errorText = "";

      this.$set(this.group, "id", groupId);
      this.$set(this.group, "name", "");

      this.dialog = true;
    },
    async duplicateGroup() {
      const isValid = await this.$validator.validateAll("form-3");

      if (!isValid) return;

      this.startVuexLoading("duplicateGroup");

      const params = [this.group.id, { name: this.group.name }];

      this.$api.groups.duplicate(...params).then(
        (response) => {
          this.$router
            .push({
              name: "groups_edit_wizard",
              params: { group_id: response.data.data.id },
            })
            .catch(() => {});

          this.endVuexLoading("duplicateGroup");
        },
        (error) => {
          if (error) {
            this.endVuexLoading("duplicateGroup");
            this.errorText = this.$t("group_list_page.duplicate_error");
          }
        },
      );
    },
  },
};
</script>
